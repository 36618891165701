import { Main } from "./Sections/Main";
import { Index } from "./Sections/Index";
import { Overview } from "./Sections/Overview";
import { HowToJoin } from "./Sections/HowToJoin";
import { Resources } from "./Sections/Resources";
import { JudgesAndMentors } from "./Sections/JudgesAndMentors";
import { ContactUs } from "./Sections/ContactUs";
import { Categories } from "./Sections/Categories";
import { useEffect } from "react";

export function InnovationCampaign() {
    useEffect(() => {
        document.querySelector('title').innerHTML = "KPJ Redefining Aspirations for Tomorrow (K.R.A.F.T.) Challenge 2025";
        document.querySelector('meta[name="keywords"]')?.setAttribute("content", "KPJ KRAFT Challenge 2025, KPJ Healthcare innovation, healthcare startup competition, medical technology challenge, healthcare digital transformation, AI in healthcare, biotechnology innovation, digital health solutions, clinical research funding, medical research grants Malaysia, healthcare sustainability projects, future of medicine, patient care innovation, smart hospital solutions, telemedicine advancements, operational efficiency in healthcare, medical device innovation, pharmaceutical research, healthcare commercialization, KPJ research and innovation, digitalisation in healthcare, Malaysia healthcare challenge, healthcare hackathon, biomedical engineering competition, university healthcare innovation, healthcare changemakers");
        document.querySelector('meta[name="description"]')?.setAttribute("content", "The K.R.A.F.T. Challenge 2025 is more than just a competition, it is a movement powered by KPJ Healthcare's commitment to innovation. If you have an idea that could redefine the future of healthcare, now is the time to bring it to life and be rewarded for it.");
    }, [])
    return <>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet" />
    <style>
        {`
        * {
            font-family: Aptos !important;
        }
        header, .kpj-footer, .navigation-bottom {
            display: none !important;
        }
        li {
            list-style: inherit !important;
        }
        .bg-gold {
            background-color: rgb(150, 127, 101) !important;
        }
        .text-gold {
            color: rgb(150, 127, 101) !important;
        }
        h1 {
            font-family: 'Montserrat' !important;
        }
        .bg-offgold {
            background-color: #F3EEEC !important;
        }
        th {
            white-space: nowrap;
        }
        `}
    </style>
    <div style={{overflowX: "hidden"}}>
        <Main />
        <Index />
        <Overview />
        <HowToJoin />
        <Categories />
        <Resources />
        <JudgesAndMentors />
        <ContactUs />
    </div>
</>
}